// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-static-views-homepage-homepage-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/homepage/Homepage.tsx" /* webpackChunkName: "component---src-static-views-homepage-homepage-tsx" */),
  "component---src-static-views-experience-experience-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/experience/Experience.tsx" /* webpackChunkName: "component---src-static-views-experience-experience-tsx" */),
  "component---src-static-views-service-service-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/service/Service.tsx" /* webpackChunkName: "component---src-static-views-service-service-tsx" */),
  "component---src-static-views-gallery-gallery-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/gallery/Gallery.tsx" /* webpackChunkName: "component---src-static-views-gallery-gallery-tsx" */),
  "component---src-static-views-faq-faq-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/faq/FAQ.tsx" /* webpackChunkName: "component---src-static-views-faq-faq-tsx" */),
  "component---src-static-views-facilities-facilities-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/facilities/Facilities.tsx" /* webpackChunkName: "component---src-static-views-facilities-facilities-tsx" */),
  "component---src-static-views-event-event-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/event/Event.tsx" /* webpackChunkName: "component---src-static-views-event-event-tsx" */),
  "component---src-static-views-careers-careers-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/careers/Careers.tsx" /* webpackChunkName: "component---src-static-views-careers-careers-tsx" */),
  "component---src-static-views-booking-homepage-booking-homepage-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/booking-homepage/BookingHomepage.tsx" /* webpackChunkName: "component---src-static-views-booking-homepage-booking-homepage-tsx" */),
  "component---src-static-views-contact-contact-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/contact/Contact.tsx" /* webpackChunkName: "component---src-static-views-contact-contact-tsx" */),
  "component---src-static-views-local-attraction-local-attraction-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/localAttraction/LocalAttraction.tsx" /* webpackChunkName: "component---src-static-views-local-attraction-local-attraction-tsx" */),
  "component---src-static-views-article-article-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/article/Article.tsx" /* webpackChunkName: "component---src-static-views-article-article-tsx" */),
  "component---src-static-views-press-and-media-press-and-media-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/press-and-media/PressAndMedia.tsx" /* webpackChunkName: "component---src-static-views-press-and-media-press-and-media-tsx" */),
  "component---src-static-views-csr-csr-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/csr/CSR.tsx" /* webpackChunkName: "component---src-static-views-csr-csr-tsx" */),
  "component---src-static-views-awards-awards-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/awards/Awards.tsx" /* webpackChunkName: "component---src-static-views-awards-awards-tsx" */),
  "component---src-static-views-news-news-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/news/News.tsx" /* webpackChunkName: "component---src-static-views-news-news-tsx" */),
  "component---src-static-views-kan-kan-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/kan/Kan.tsx" /* webpackChunkName: "component---src-static-views-kan-kan-tsx" */),
  "component---src-static-views-shop-item-category-shop-item-category-tsx": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/static-views/shop-item-category/ShopItemCategory.tsx" /* webpackChunkName: "component---src-static-views-shop-item-category-shop-item-category-tsx" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src699822692/src/frontend-with-prismic/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

